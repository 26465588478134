html {
  scroll-behavior: smooth;
}

::selection {
  color: $white;
  background-color: $secondary;
}

::-webkit-scrollbar {
  @include media-breakpoint-up(lg) {
    height: 2px;
    width: 4px;
  }
}

::-webkit-scrollbar-thumb {
  @include media-breakpoint-up(lg) {
    background-color: rgba($color: $secondary, $alpha: 0.75);
    border-radius: 20px;
    height: auto;
  }
}

html,
body {
  font-size: rem(16);
  line-height: 22.5px;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  background-color: $body-bg;
  color: $dark;
}

a,
button,
.c-pointer,
.auth-link {
  cursor: pointer;
  @include default_transition(0.3s, ease-in-out);
}

a {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
  }
}

a.disabled {
  cursor: auto;
}

.link-hover {
  &:hover {
    color: $primary;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-black;
  font-family: $font-family-black;
  @include media-breakpoint-down(xl) {
    font-family: $font-family-ExtraBold;
    font-weight: $font-weight-extrabold;
  }
}

h1,
.h1 {
  font-size: rem(90);
  line-height: 81px;
  @include media-breakpoint-down(xxl) {
    font-size: rem(72);
    line-height: 64px;
  }
  @include media-breakpoint-down(xl) {
    font-size: rem(54);
    line-height: 48px;
  }
  @include media-breakpoint-down(lg) {
    font-size: rem(42);
    line-height: 38px;
  }
  @include media-breakpoint-down(md) {
    font-size: rem(36);
    line-height: 33px;
  }
}

h2,
h2 {
  font-size: rem(50);
  line-height: 45px;
  @include media-breakpoint-down(xxl) {
    font-size: rem(42);
    line-height: 38px;
  }
  @include media-breakpoint-down(lg) {
    font-size: rem(36);
    line-height: 33px;
  }
  @include media-breakpoint-down(md) {
    font-size: rem(32);
    line-height: 28px;
  }
}

h3,
.h3 {
  font-size: rem(25);
  line-height: 28px;
  @include media-breakpoint-down(md) {
    font-size: rem(21);
    line-height: 24px;
  }
}

.font-400 {
  font-weight: $font-weight-normal;
}

.font-700 {
  font-weight: $font-weight-bold;
}

.font-800 {
  font-weight: $font-weight-bold;
  font-family: "Akrobat ExtraBold Regular";
}

.font-900 {
  font-family: "Akrobat Black";
  font-weight: $font-weight-black;
}

.row-gx-20 {
  --bs-gutter-x: 1.25rem;
}

.row-gx-30 {
  --bs-gutter-x: 1.875rem;
}

.google-map .map-container {
  min-height: 450px;
}
