.form-group {
  @extend .position-relative;
  &:not([class*=" mb-"]) {
    @extend .mb-18;
  }
  label {
    &:not(.form-check-label) {
      @extend .mb-6;
      @extend .font-700;
    }
  }
  &-pwd {
    .form-group-error {
      .input-group-text {
        border-top-right-radius: 100px !important;
        border-bottom-right-radius: 100px !important;
      }
      .error-icon {
        right: 48px;
      }
    }
  }
}
.form-control {
  @include input-placeholder {
    opacity: 0.4;
  }
  &:focus {
    box-shadow: none;
    outline: none;
    @include input-placeholder {
      opacity: 0;
    }
  }
}

.input-group {
  .form-control {
    @extend .border-end-0;
  }
  .input-group-text {
    @extend .pe-20;
    @extend .bg-transparent;
  }
}
.search-control {
  @extend .position-relative;
  .form-control {
    background-color: $gray-100;
    @include media-breakpoint-up(xl) {
      min-width: 596px;
    }
    @include media-breakpoint-only(lg) {
      min-width: 370px;
    }
    @include media-breakpoint-down(sm) {
      padding: 0.75rem 1.25rem;
    }
  }
  .btn-search {
    @extend .border-0;
    @extend .btn-clear;
    @extend .p-0;
    @extend .position-absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 20px;
    line-height: 1;
  }
}

.form-check-input {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.error-msg {
  margin-top: 5px;
  padding-left: 20px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: $danger;
}

.form-group-error {
  @extend .position-relative;
  .form-control {
    color: $danger;
  }
  .error-icon {
    @extend .position-absolute;
    right: 30px;
    top: 16px;
  }
}

.form-group-success {
  .success-icon {
    @extend .position-absolute;
    right: 24px;
    top: 14px;
  }
}
.form-check-input {
  border-color: $dark;
}

.form-control-error {
  border-color: $danger;
}
