app-my-programs,
app-account-info,
app-users,
app-faq,
app-organization-list {
  @extend .w-100;
}
.page-content {
  @extend .position-relative;
  @extend .w-100;
  overflow-y: auto;
  padding-bottom: 60px;
}

.account-wrapper {
  background: $gray-100;
  @extend .d-flex;
  @extend .position-relative;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  .account-content {
    @extend .position-relative;
    @extend .w-100;
    padding: 40px 60px 40px calc($sidebar-width + 40px);
    overflow-y: auto;
    .container {
      @extend .ms-0;
      @include media-breakpoint-up(xl) {
        width: 1080px;
        max-width: 100%;
      }
    }
    @include media-breakpoint-down(xl) {
      padding: 24px;
    }
    @include media-breakpoint-down(xl) {
      padding: 16px 4px;
    }
  }
  .page-header {
    margin-bottom: 60px;
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .flex-wrap;
    .account-heading {
      @extend .mb-0;
    }
    @include media-breakpoint-down(xl) {
      margin-bottom: 40px;
      .btn {
        margin-top: 12px;
      }
    }
  }
  .card-heading {
    opacity: 0.4;
    @extend .mb-10;
  }
  .ai-item {
    margin-bottom: 40px;
    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }
  .info-row {
    @extend .row;
    @extend .gx-0;
    padding: 14px 0;
    &:not(:last-child) {
      @extend .border-bottom;
    }
    & > * {
      @extend .col-12;
      @extend .col-md-6;
    }
  }
  .info-item {
    @extend .flexbox;

    p {
      @extend .mb-0;
    }
    &:not(:last-child) {
      @extend .mb-1;
      @extend .mb-md-0;
    }
  }
}

.user-view {
  @extend .d-flex;
  @extend .flex-wrap;
  @extend .align-items-center;
  &-sm {
    margin-bottom: 20px;
    .user-avatar {
      width: 101px !important;
      height: 101px !important;
    }
  }
  .user-avatar {
    @extend .rounded-circle;
    @extend .position-relative;
    @extend .overflow-hidden;
    width: 150px;
    height: 150px;
    img {
      object-fit: cover;
      @extend .w-100;
      @extend .h-100;
    }
    &:hover {
      .user-action {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .user-info {
    @extend .ms-20;
  }
  @include media-breakpoint-down(md) {
    .user-avatar {
      width: 100px;
      height: 100px;
    }
  }
}
.user-action {
  @extend .position-absolute;
  @extend .top-0;
  @extend .start-0;
  @extend .flexbox-align-c;
  @extend .flex-column;
  @extend .text-white;
  @extend .w-100;
  @extend .h-100;
  background-color: rgba($color: $primary, $alpha: 0.6);
  @include default_transition(0.3s, ease-in-out);
  opacity: 0;
  visibility: hidden;
}
.team-member {
  @extend .mt-30;
  @extend .text-center;
  @extend .d-flex;
  @extend .flex-wrap;

  &-item {
    @extend .d-inline-flex;
    @extend .d-flex;
    @extend .flex-column;
    margin-right: 20px;
  }
  .choose-file {
    @extend .mx-auto;
    @extend .mb-20;
    @extend .rounded-circle;
    @extend .position-relative;
    width: 101px;
    height: 101px;
    label {
      @extend .me-0;
      @extend .w-100;
      @extend .h-100;
    }
    &:hover {
      .user-action {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
