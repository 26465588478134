//
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f6f6f6; // Grey/Background
$gray-200: #f7f9fb; // input bg color, secondary
$gray-300: #eceded; // border color eceded
$gray-400: #e6e6e6;
$gray-500: #6d7479;
$gray-600: #b9b9b9; // footer text color, text muted
$gray-700: #7c7c7c; // form label and link text
$gray-800: #1f272c; // footer bg color
$gray-900: #002233; // dark color, footer bg
$black: #000000;

/*Theme color*/
$primary: #00697d; // Blue/Primary
$secondary: #ffb437; // Yellow/Accent
$dark: #002233; // #002233
$blue: #73ebff; // Light Blue/Primary
$danger: #9e0000;
$bluebase: #00989e; // Blue Base
$lightblue: #edfbff; // Light blue/Base
$blueaccent: #39c1ce; // Blue Accent
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger,
  "dark": $dark
);

$theme-colors: map-remove($theme-colors, "warning", "success", "info");

$font-weight-base: $font-weight-normal !default;

$breadcrumb-active-color: rgba($black, 0.5);
$breadcrumb-item-padding-x: 0.25rem;
$input-padding-x: 1.25rem !default;
$font-weight-extrabold: 800 !default;
$font-weight-black: 900 !default;
$link-color: $dark;
$link-hover-color: $dark;

// button
$btn-border-radius: 100px;
$btn-border-radius-lg: 100px;
$input-border-radius: 100px;
$btn-line-height: 1.406rem;
$btn-border-radius-sm: 100px;

$border-color: rgba($dark, 0.1);

// input
$input-padding-y: 1rem;
$input-padding-x: 1.25rem;
$input-focus-box-shadow: none;
$border-radius-lg: 30px;
$input-line-height: 1.406rem;
$input-border-color: $dark;
$input-focus-border-color: $dark;
$input-border-radius-sm: $btn-border-radius;
$input-group-addon-border-color: $dark;

$form-check-input-width: 1.75rem;
$form-check-padding-start: 2.5rem;
$form-check-input-checked-bg-color: $secondary;
$form-check-input-checked-border-color: $secondary;
$form-check-input-focus-border: $dark;
