.mat-tab-group {
  margin-bottom: 48px;
}
.mat-mdc-tab-header {
  @extend .container;
}
.mat-mdc-tab {
  background-color: #e1e1e1 !important;
  border-radius: 10px 10px 0 0;
  & + .mat-mdc-tab {
    margin-left: 5px;
  }
}
.mdc-tab--active {
  background-color: #f6f6f6 !important;
}
.mdc-tab-indicator__content--underline {
  @extend .border-0;
}
.mdc-tab__text-label {
  @extend .text-dark;
}
