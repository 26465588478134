$sidebar-width: 263px;
:root {
  --header-height: 112px;
  --section-gutter-y-lg: 100px;
  --section-gutter-y: 60px;
  --section-gutter-y-sm: 30px;
  --section-gutter-y-xs: 10px;

  @include media-breakpoint-down(xl) {
    --section-gutter-y-lg: 50px;
    --section-gutter-y: 40px;
    --section-gutter-y-sm: 20px;
  }
  @include media-breakpoint-down(md) {
    --section-gutter-y-lg: 40px;
    --section-gutter-y: 30px;
    --section-gutter-y-sm: 10px;
  }
}
