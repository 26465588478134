// Componant: button

button,
.btn {
  cursor: pointer;
  @include default_transition(0.3s, ease-in-out);
  outline: none !important;
  box-shadow: none !important;
  &:focus,
  &:hover {
    outline: none !important;
    box-shadow: none !important;
  }
}

.btn {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  font-weight: $font-weight-bold;
  border-width: 2px;
  @include media-breakpoint-down(lg) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @include media-breakpoint-down(md) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    &.btn-w-icon {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  @include media-breakpoint-down(sm) {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
    &.btn-w-icon {
      padding: 6px 18px;
    }
  }
  &.disable {
    opacity: 0.4;
    cursor: default;
    &:focus,
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      background-color: var(--bs-btn-bg);
      color: var(--bs-btn-color);
    }
  }
}
.btn-sm {
  padding: 6px 20px !important;
  border-width: 1px;
  @include media-breakpoint-down(md) {
    padding: 6px 16px !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}
.btn-lg {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  @include media-breakpoint-up(md) {
    padding-left: 60px;
    padding-right: 60px;
    & + .btn-lg {
      margin-left: 20px;
    }
  }
  @include media-breakpoint-down(md) {
    & + .btn-lg {
      margin-left: 10px;
    }
  }
}

.btn-light {
  background-color: $white;
  // color: $;
  &:hover,
  &:focus {
    background-color: transparent;
    border-color: $white;
  }
  &.text-primary {
    &:hover,
    &:focus {
      color: $white !important;
    }
  }
}
.btn-outline-light {
  border-color: $body-bg;
  color: $body-bg;
  &:hover,
  &:focus {
    background-color: $body-bg;
    border-color: $body-bg;
    color: $primary;
  }
}

.btn-secondary {
  &:hover,
  &:focus {
    background-color: transparent;
  }
}

.btn-px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.btn-py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
// .btn-outline-secondary {
//   border-width: 1px;
//   svg path {
//     @include default_transition(0.3s, ease-in-out);
//   }
//   &:hover,
//   &:focus {
//     color: $white !important;
//     svg path {
//       stroke: $white;
//     }
//   }
// }

.btn-load-more {
  min-width: 200px;
  @extend .btn;
  @extend .btn-lg;
  @extend .btn-outline-secondary;
  @extend .text-secondary;
}

.btn-clear {
  @extend .btn;
  @extend .p-0;
  @extend .border-0;
}
.btn-w-icon {
  .icon {
    margin-left: 5px;
  }
}

.btn-icon {
  width: 44px;
  height: 44px;
  @extend .flexbox-align-c;
  @extend .p-0;
}

.arrow-icon {
  line-height: 22.5px;
  vertical-align: text-bottom;
}

.btn-group-filter {
  @extend .d-flex;
  @extend .flex-wrap;
  .btn {
    @extend .font-400;
    @extend .mb-1;
    border-width: 1px;
    padding: 3px 10px 3px 20px;
    font-size: rem(13);
    line-height: 18.2px;
    svg,
    img {
      @extend .ms-10;
    }
    &:not(:last-child) {
      @extend .me-10;
    }
  }
  @include media-breakpoint-down(lg) {
    .btn {
      padding: 3px 10px 3px 20px;
      svg,
      img {
        margin-left: 6px !important;
      }
    }
  }
}
.btn-outline-secondary {
  color: $dark;
}
.btn-group-secondary {
  .btn {
    background-color: #ffc769;
    @extend .mb-1;
    &:not(:last-child) {
      @extend .me-10;
    }
  }
}

.tabs-btn-group {
  @extend .mb-60;
  @extend .bg-white;
  @extend .rounded-pill;
  @extend .d-inline-flex;
  padding-left: 40px;
  padding-right: 40px;
  gap: 40px;
  .btn {
    @extend .btn-clear;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .active {
    @extend .text-primary;
    @extend .text-decoration-underline;
  }
  @include media-breakpoint-down(sm) {
    padding-left: 16px;
    padding-right: 16px;
    gap: 16px;
    .btn {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
  }
}

.btn-disable {
  @extend .disable;
  background-color: #809099 !important;
  border-color: #809099 !important;
  opacity: 1 !important;
  color: $white !important;
}
