// App
.app-wrapper {
  @extend .position-relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-content {
  @extend .mt-60;
  @extend .position-relative;
  flex: 1;
  @include media-breakpoint-down(md) {
    margin-top: 20px !important;
  }
}
.bg-pattern {
  padding: 50px;
  border-radius: $border-radius-lg;
  background-repeat: no-repeat;
  background-position: center right;
  @include media-breakpoint-down(xl) {
    padding: 30px;
  }
  @include media-breakpoint-down(md) {
    padding: 24px;
  }
  @include media-breakpoint-down(sm) {
    border-radius: 20px;
  }
}
.pattern-primary {
  @extend .bg-primary;
  @extend .text-white;
  background-image: url("../../images/bg-pattern.svg");
}
.pattern-secondary {
  @extend .bg-secondary;
  @extend .text-dark;
  background-image: url("../../images/bg-pattern-secondary.svg");
}
.section-landing {
  @include media-breakpoint-up(xxl) {
    .section-banner {
      min-height: 650px;
    }
  }
  .section-banner {
    min-height: 450px;
  }
  .page-heading {
    margin-bottom: 100px;
  }
  p {
    @extend .mb-60;
  }
  @include media-breakpoint-down(xxl) {
    .page-heading {
      margin-bottom: 60px;
    }
    p {
      margin-bottom: 40px;
    }
  }
  @include media-breakpoint-down(lg) {
    .section-banner {
      min-height: unset;
    }
  }
  @include media-breakpoint-down(md) {
    .page-heading {
      margin-bottom: 40px;
    }
  }
}
.page-heading-sm {
  @include media-breakpoint-up(lg) {
    font-size: rem(60);
    line-height: 54px;
  }
  @include media-breakpoint-down(sm) {
    font-size: rem(28);
    line-height: 32px;
  }
  @extend .mb-0;
}

.section {
  @extend .position-relative;
}

.section-py {
  padding-top: var(--section-gutter-y);
  padding-bottom: var(--section-gutter-y);
}

.section-py-lg {
  padding-top: var(--section-gutter-y-lg);
  padding-bottom: var(--section-gutter-y-lg);
}

.section-py-sm {
  padding-top: var(--section-gutter-y-sm);
  padding-bottom: var(--section-gutter-y-sm);
}
.section-py-xs {
  padding-top: var(--section-gutter-y-xs);
  padding-bottom: var(--section-gutter-y-xs);
}

.section-steps {
  margin-top: 30px;
}
