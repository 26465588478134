.h-stepper {
  margin-top: 30px;
  @extend .mb-60;
  display: flex;
  --circle-size: 60px;
  --spacing: clamp(0.25rem, 2vw, 0.5rem);

  &-item {
    @extend .position-relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;

    .stepper-icon {
      margin-top: -75px;
      width: 60px;
      height: 60px;
      cursor: pointer;
      @extend .mx-auto;
      @extend .flexbox-align-c;
    }

    &:before {
      content: "";
      display: block;
      width: var(--circle-size);
      height: var(--circle-size);
      border-radius: 50%;
      @extend .bg-white;
      margin: 0 auto 1rem;
      border: 2px solid $primary;
    }
    &.active {
      &:before {
        @extend .bg-primary;
      }
    }

    &:not(:last-child) {
      &:after {
        content: "";
        position: relative;
        top: calc(var(--circle-size) / 2);
        width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
        left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
        height: 2px;
        @extend .bg-primary;
        order: -1;
      }
    }
  }

  &-title {
    @extend .mt-10;
    @extend .mb-0;
    @extend .font-700;
  }
}

.calmer-list {
  max-width: 775px;
  @extend .mx-auto;
  @extend .d-flex;
  gap: 30px;
  .calmer-item {
    flex: 1 1 0;
    @extend .text-center;
    @extend .px-3;
    @extend .c-pointer;
  }
  .calmer-img {
    @extend .border;
    @extend .rounded-pill;
    @extend .overflow-hidden;
    @extend .mx-auto;
    @extend .flexbox-align-c;
    @extend .mb-20;
    border-color: $primary !important;
    width: 136px;
    height: 136px;
    img {
      max-height: 106px;
      object-fit: contain;
    }
  }
}
.filter-show {
  overflow: hidden;
  .filter-bar {
    opacity: 1;
    visibility: visible;
  }
}
body {
  &.utility-toggle {
    .filter-bar {
      top: var(--header-height) !important;
    }
  }
}
.filter-bar {
  top: calc(var(--header-height) + 46px) !important;

  @extend .fixed-top;
  @extend .bg-white;
  opacity: 0;
  visibility: hidden;
  .fb-body {
    padding: 30px;
    @extend .shadow;
    border-radius: 12px;
  }

  .fb-body-scroll {
    max-height: 60vh;
    overflow-y: auto;
  }

  .choose-list {
    @extend .mt-0;
    @extend .mb-10;
    .form-check-label {
      padding: 6.75px 25px !important;
      @extend .font-14;
    }
    .radio-item {
      &:not(:last-child) {
        @extend .me-10;
      }
    }
  }
  .f-close {
    @extend .position-absolute;
    top: 8px;
    right: 16px;
    @extend .btn;
    @extend .btn-clear;
  }
  @include media-breakpoint-down(md) {
    .fb-body {
      padding: 30px 20px;
    }
    .fb-body-scroll {
      max-height: 48vh;     
    }
    .choose-list {
      .form-check-label {
        padding: 6.75px 16px !important;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    top: 120px !important;
  }
}

.utility-bar {
  @include default_transition(0.3s, ease-in-out);
  .utility-content {
    padding: 10px 20px 10px 10px;
    background-color: $secondary;
  }
  .text {
    @extend .text-center;
    @extend .mb-0;
    @extend .font-700;
  }
  a {
    @extend .text-decoration-underline;
  }
  @include media-breakpoint-down(md) {
    .utility-content {
      padding: 8px 16px 8px 8px;
    }
    .text {
      font-size: 12px;
    }
  }
}
.utility-close {
  @extend .position-absolute;
  @extend .text-white;
  @extend .btn;
  @extend .btn-clear;
  @extend .rounded-0;
  right: calc(var(--bs-gutter-x) * 0.5);
  line-height: 0;
  width: 17px;
  height: 17px;
  top: 3.5px;
  transition: none;
  z-index: 2;

  @include media-breakpoint-down(xl) {
    right: 6px;
  }
  @include media-breakpoint-down(md) {
    right: 0;
    top: 2px;
  }
}
.utility-open {
  @extend .utility-close;
  background-color: $primary;
  width: 32px;
  height: 32px;
  right: 30px;
  opacity: 0;
  visibility: hidden;
  &:hover,
  &:focus {
    background-color: $primary;
  }
}

.utility-toggle {
  .utility-bar {
    @extend .d-none;
  }
  .utility-open {
    opacity: 1;
    visibility: visible;
  }
}

.stories-photo {
  @extend .list-unstyled;
  @extend .d-flex;
  @extend .flex-wrap;
  @extend .mb-0;
  gap: 10px;
  .sp-item {
    .btn-clear {
      @extend .position-relative;
      &:after {
        content: "";
        @extend .w-100;
        @extend .h-100;
        @extend .rounded-circle;
        @extend .overflow-hidden;
        @extend .position-absolute;
        @extend .top-0;
        @extend .start-0;
        border: 3px solid transparent !important;
      }
      &.active {
        &:after {
          border-color: $secondary !important;
        }
      }
    }
  }
  .sp-img {
    @extend .rounded-circle;
    width: 94px;
    height: 94px;
    object-fit: cover;
  }
  .sp-name {
    @extend .mt-10;
    @extend .font-700;
    @extend .text-center;
  }

  @include media-breakpoint-down(md) {
    .sp-img {
      width: 72px;
      height: 72px;
    }
  }
}

.sp-content {
  &-sort {
    @extend .overflow-hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    p {
      display: contents;
      @extend .mb-0;
    }
  }
  a {
    @extend .link;
    @extend .text-primary;
    span,
    u {
      @extend .text-primary;
    }
  }
}

.sec-pass {
  @extend .d-flex;
  @extend .border;
  width: 20px;
  height: 5px;
  border-color: $primary !important;
  border-radius: 100px;
  & + .sec-pass {
    margin-left: 5px;
  }
  &.fill {
    @extend .bg-primary;
  }
}
