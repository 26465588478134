.auth-page {
  min-height: 100vh;

  @include media-breakpoint-up(xl) {
    display: flex;
    background-color: $gray-100;
  }

  .auth-image {
    display: none;
    @include media-breakpoint-up(xl) {
      display: block;
      width: calc(50% + 28px);
      background-size: cover;
      .auth-logo {
        padding: 30px 60px;
      }
    }
  }

  .auth-form {
    width: 100%;
    @include media-breakpoint-up(xl) {
      width: calc(50% - 28px);
    }
    @extend .position-relative;
    &:after {
      content: "";
      @extend .position-absolute;
      @extend .top-0;
      left: -28px;
      @extend .bg-white;
      @extend .h-100;
      width: calc(100% + 28px);
      border-radius: 30px 0px 0px 30px;
    }
  }

  .signin-bg {
    background-image: url("../../images/signin-img.png");
  }
  .password-bg {
    background-image: url("../../images/forgot-password-img.png");
  }
}

.form-card {
  @extend .position-relative;
  @extend .mx-auto;
  @extend .w-100;
  z-index: 1;
  margin: 72px 36px 36px;
  max-width: 440px;
  @include media-breakpoint-down(md) {
    padding: 1rem;
    margin: 36px 18px 18px;
  }
}
.ap-back {
  @extend .position-absolute;
  @extend .btn-clear;
  right: 60px;
  top: 30px;
  z-index: 2;
  @include media-breakpoint-down(md) {
    right: 0;
    top: 0;
  }
}
.card {
  @extend .border-0;
  .card-body {
    padding: 50px;
  }
  @include media-breakpoint-down(xl) {
    .card-body {
      padding: 30px;
    }
  }
  @include media-breakpoint-down(lg) {
    .card-body {
      padding: 24px;
    }
  }
  @include media-breakpoint-down(md) {
    .card-body {
      padding: 16px !important;
    }
  }
}
