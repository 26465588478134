ul {
  list-style-type: none;
}

.accordion {
  @extend .ps-0;
  max-width: 736px;
  .menu {
    position: relative;
    padding: 25px 25px 25px 0;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.4s ease;
    @include media-breakpoint-down(md) {
      padding: 20px;
    }
  }
}

.accordion > li {
  &:not(:last-child) {
    @extend .border-bottom;
  }
}

.accordion li:last-child .menu {
  border-bottom: 0;
}

.accordion {
  li {
    &:first-child {
      .menu {
        @extend .pt-0;
      }
      .icon-chevron {
        top: 0;
      }
    }

    .icon-chevron {
      @extend .position-absolute;
      @extend .end-0;
      top: 20px;
      transition: all 0.4s ease;
      .ic-minus {
        opacity: 0;
        visibility: hidden;
      }
    }
    &.active {
      .icon-chevron {
        .ic-minus {
          opacity: 1;
          visibility: visible;
        }
        .ic-plus {
          opacity: 0;
          visibility: hidden;
        }
      }
      .submenu {
        height: auto !important;
      }
      h3 {
        color: $primary;
      }
    }
    @include media-breakpoint-down(md) {
      top: 14px;
    }
  }
}

.submenu {
  height: 0;
  overflow: hidden;
  transition: height 0.4s ease;
  padding-left: 0;
  p {
    max-width: 677px;
    padding-right: 30px;
  }
}
