// Fonts
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@font-face {
  font-family: "Akrobat Regular";
  font-style: normal;
  font-weight: normal;
  src:
    local("Akrobat Regular"),
    url("../../fonts/Akrobat-Regular.woff") format("woff");
}

@font-face {
  font-family: "Akrobat Light";
  font-style: normal;
  font-weight: normal;
  src:
    local("Akrobat Light"),
    url("../../fonts/Akrobat-Light.woff") format("woff");
}

@font-face {
  font-family: "Akrobat ExtraLight";
  font-style: normal;
  font-weight: normal;
  src:
    local("Akrobat ExtraLight"),
    url("../../fonts/Akrobat-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Akrobat SemiBold";
  font-style: normal;
  font-weight: normal;
  src:
    local("Akrobat SemiBold"),
    url("../../fonts/Akrobat-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Akrobat Bold";
  font-style: normal;
  font-weight: normal;
  src:
    local("Akrobat Bold"),
    url("../../fonts/Akrobat-Bold.woff") format("woff");
}

@font-face {
  font-family: "Akrobat ExtraBold Regular";
  font-style: normal;
  font-weight: normal;
  src:
    local("Akrobat ExtraBold Regular"),
    url("../../fonts/akrobat-extrabold-webfont.woff") format("woff");
}

@font-face {
  font-family: "Akrobat Black";
  font-style: normal;
  font-weight: normal;
  src:
    local("Akrobat Black"),
    url("../../fonts/Akrobat-Black.woff") format("woff");
}

$font-family: "Open Sans", sans-serif;
$font-family-Regular: "Akrobat Regular";
$font-family-SemiBold: "Akrobat SemiBold";
$font-family-Bold: "Akrobat Bold";
$font-family-ExtraBold: "Akrobat ExtraBold Regular";
$font-family-black: "Akrobat Black";
