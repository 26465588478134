/*----- Componant: Top Navigation Bar ----- */
.header {
  // @extend .fixed-top;
  @include default_transition(0.2s, ease-in-out);

  // padding-bottom: 29px;
  z-index: 1000;

  // &.headerscroll {
  //   padding-top: 16px !important;
  //   padding-bottom: 16px !important;
  //   &:not(.header-fill) {
  //     @extend .shadow-sm;
  //     @extend .bg-white;
  //   }
  //   .navbar-btn {
  //     .btn-outline {
  //       @include media-breakpoint-up(lg) {
  //         border-color: $success;
  //         color: $success;
  //       }
  //     }
  //     .btn {
  //       transition: none;
  //     }
  //   }
  // }
  .header-nav {
    @include default_transition(0.2s, ease-in-out);
  }
  .navbar {
    padding: 29px 0 25px;
  }
  .navbar-nav {
    &.navbar-menu {
      @extend .mx-auto;
      .nav-item {
        &:not(:last-child) {
          margin-right: 46px;
        }
      }
    }

    .nav-link {
      transition: none;
      color: $body-color;
      &.active,
      &:hover,
      &:focus {
        color: $secondary;
      }
    }
    @include media-breakpoint-down(xl) {
      &.navbar-menu {
        .nav-item {
          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }
  }
  .menu-wrapper {
    // @extend .w-100;
    // @extend .d-flex;
    // @extend .justify-content-between;
  }
  .navbar-brand {
    color: $primary;
    margin-right: 1.25rem;
    @extend .p-0;
    @extend .flex-shrink-0;
  }
  .search-bar {
    @extend .d-flex;
  }
  .navbar-btn {
    .nav-item-login {
      margin-left: 10px;
      margin-right: 10px;
    }
    @include media-breakpoint-down(xl) {
      .nav-item-login {
        margin-left: 0;
      }
    }
  }
  .btn-menu-toggle {
    display: none !important;
    &:hover,
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      border-color: transparent !important;
    }
    @include media-breakpoint-down(lg) {
      display: block !important;
    }
  }
  .icon-cross {
    display: none;
  }
  @include media-breakpoint-down(lg) {
    .menu-wrapper {
      display: none !important;
    }
  }
  @include media-breakpoint-down(md) {
    .navbar {
      padding: 16px 0;
    }
  }

  &-fill {
    @extend .bg-primary;
    &.headerscroll {
      @extend .bg-primary;
    }
    .navbar-nav {
      .nav-link {
        color: $body-bg;
        &.active,
        &:hover,
        &:focus {
          color: $secondary;
        }
      }
    }
    .navbar-brand,
    .icon-menu,
    .icon-cross {
      color: $body-bg;
    }
    .navbar-btn {
      .nav-link {
        color: $body-bg;
      }
    }
  }
}
.menu-toggle {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
  .page-content {
    pointer-events: none;
  }
  .menu-wrapper {
    display: block !important;
    @extend .fixed-top;
    top: 99px;
    width: calc(100% - 60px);
    flex-direction: column;
    left: 0;
    padding: 16px 12px;
    .navbar-menu {
      flex-direction: column;
      margin-left: 0 !important;
      .nav-item {
        display: flex;
        margin-bottom: 4px;
      }
    }
    .navbar-btn {
      margin-top: 8px;
      margin-left: 0 !important;
    }
  }
  .btn-menu-toggle {
    .icon-menu {
      display: none;
    }
    .icon-cross {
      display: block;
    }
  }
}
.menu-toggle {
  .header {
    .menu-wrapper {
      background-color: $white;
    }
  }
  .header-fill {
    .menu-wrapper {
      background-color: $primary;
    }
  }
}
.page-header {
  .header {
    &:not(.header-scroll) {
      border-color: transparent !important;
      .nav-item,
      .dropdown {
        & > .nav-link {
          @extend .text-white;
          .dd-icon {
            .dark {
              fill: $white;
            }
          }
        }
      }
    }
  }
  .navbar-btn {
    .btn-outline {
      border-color: $white;
      color: $white;
    }
  }
}

.nav-menu {
  @extend .border-top;

  &-row {
    @extend .d-lg-flex;
    @extend .align-items-center;
    @extend .justify-content-between;
  }
  .nav {
    @extend .ms-auto;
    @extend .mt-3;
    @extend .mt-lg-0;
  }
  .nav-item {
    &:last-child {
      margin-left: 8px;
      .nav-link {
        @extend .pe-0;
      }
    }
  }
  .nav-link {
    &.caret {
      padding-right: 25px;
      background-image: url("../../images/caret-down-outline.svg");
      background-repeat: no-repeat;
      background-position: center right;
    }
  }
  @include media-breakpoint-up(sm) {
    padding-top: 20px;
  }
  @include media-breakpoint-down(md) {
    .nav-link {
      --bs-nav-link-padding-x: 0.5rem;
    }
  }
}
