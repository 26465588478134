/*!
 * CMHA
 */

@import "bootstrap/functions";
@import "bootstrap/variables";

// Customize Bootstrap
@import "custombootstrap/fonts";
@import "custombootstrap/customize-bootstrap";

// Bootstrap
@import "bootstrap/bootstrap";

// Partial --  Custom mixins and function
@import "partial/mixins";
@import "partial/function";

// Basics Files
@import "custombootstrap/vars";
@import "custombootstrap/reset";
@import "custombootstrap/flexbox";
@import "custombootstrap/app";
@import "custombootstrap/utility";
@import "custombootstrap/ovarlay";

// Component
@import "component/accordion";
@import "component/header";
@import "component/sidebar";
@import "component/forms";
@import "component/tables";
@import "component/tabs";
@import "component/loader";
@import "component/button";
@import "component/breadcrumb";
@import "component/modal";
@import "component/nav";
@import "component/list";
@import "component/alert";
@import "component/badge";
@import "component/dropdown";
@import "component/tooltip";
@import "component/pagination";
@import "component/component";
@import "component/card";
@import "component/slider";
@import "component/footer";

// Responsive
@import "custombootstrap/media";

// Pages
@import "custombootstrap/auth";
@import "custombootstrap/account";
