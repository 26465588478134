// Componant: Sidebar
.app-sidebar {
  @extend .position-fixed;
  @extend .start-0;
  @extend .top-0;
  height: 100vh;
  width: $sidebar-width;
  background: $primary;
  overflow-y: auto;
  z-index: 999;
  border-radius: 0px 30px 30px 0px;
  color: rgba($color: $white, $alpha: 0.5);
  @extend .d-flex;
  @extend .flex-column;
  a {
    &:hover {
      color: $white;
    }
  }
  .as-header {
    margin-bottom: 40px;
    padding: 20px;
  }
  .as-menu {
    padding: 0 20px;
    &-item {
      @extend .position-relative;
    }
    &-bottom {
      @extend .mt-auto;
    }
    &-s {
      padding-top: 20px;
      border-top: 1px solid rgba($color: $white, $alpha: 0.2);
    }
  }
  .nav-list {
    @extend .d-flex;
    @extend .flex-column;
    gap: 15px;
  }
  .as-inner-item {
    @extend .d-flex;
    @extend .align-items-center;
    &.active,
    &:hover {
      color: rgba($color: $white, $alpha: 1);
    }
  }
  .as-icon {
    width: 24px;
    height: 24px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .as-item-text {
    @extend .font-700;
    @extend .ms-20;
  }

  @include media-breakpoint-down(xl) {
    left: -$sidebar-width !important;
  }
}

.menu-footer {
  padding: 20px 20px 24px;
  padding-top: 30px;
  @extend .font-12;
  line-height: 16.8px;
  p {
    @extend .mb-0;
  }
}
