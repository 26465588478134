.mat-mdc-dialog-container .mdc-dialog__content {
  font-family: $font-family !important;
}
.dialog-container {
  --mdc-dialog-supporting-text-color: $dark;
  font-family: $font-family;
  &-lg {
    @include media-breakpoint-up(md) {
      width: 666px;
    }
  }
  &-md {
    @include media-breakpoint-up(md) {
      width: 500px;
    }
  }
  .mdc-dialog__content {
    padding: 40px 30px !important;
    @include media-breakpoint-down(md) {
      padding: 30px 20px !important;
    }
  }
  .model-close {
    @extend .position-absolute;
    @extend .end-0;
    @extend .top-0;
    @extend .btn-clear;

    z-index: 2;
  }
}
.dialog-basic {
  .mdc-dialog__content {
    padding: 60px 30px !important;
  }
}
.dialog-content {
  font-family: $font-family !important;
  @extend .text-center;
}
