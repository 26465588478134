.card-full {
  @extend .h-100;
  border-radius: $border-radius-lg;

  &.bg-dark {
    .card-body {
      justify-content: space-between;
    }
    .card-content {
      margin-top: unset !important;
    }
    .cf-footer {
      margin-top: 60px !important;
    }
  }

  .card-heading {
    @extend .mb-60;
  }
  .card-body {
    @extend .d-flex;
    @extend .flex-column;
  }
  .card-content {
    @extend .mt-auto;
  }
  .cf-footer {
    @extend .mt-14;
    @extend .d-flex;
  }
  @include media-breakpoint-down(xl) {
    .card-heading {
      margin-bottom: 40px;
    }
  }
  @include media-breakpoint-down(lg) {
    .card-heading {
      margin-bottom: 24px;
    }
    &.bg-dark {
      .cf-footer {
        margin-top: 40px !important;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    border-radius: 20px;
  }
}

.bg-dark {
  .btn-secondary {
    &:hover,
    &:focus {
      @extend .text-white;
    }
  }
}

.card-steps {
  background-color: $gray-100;
  border-radius: $border-radius-lg;
  @include media-breakpoint-down(sm) {
    border-radius: 20px;
  }
}

.resources-card {
  .col {
    @extend .mb-20;
  }
  .card {
    @extend .h-100;
    @include default_transition(0.3s, ease-in-out);
    background-repeat: no-repeat;
    background-position: top right;
    &:hover {
      background-image: url("../../images/resource-pattern.svg");
    }

    .sc-header {
      @extend .mb-30;
      @extend .d-flex;
      @extend .justify-content-between;
      @extend .align-items-center;
      .sc-icons {
        @extend .d-flex;
        @extend .flex-wrap;
        @extend .align-items-center;
      }
      .sc-time {
        @extend .ms-20;
        opacity: 0.4;
      }
      a {
        width: 50px;
        height: 50px;
        @extend .bg-primary;
        @extend .flexbox-align-c;
        @extend .rounded-pill;
        @extend .overflow-hidden;
        @extend .border;
        border-color: $primary;
        & + a {
          margin-left: 5px;
        }
        img {
          @extend .rounded-pill;
          width: 30px;
          height: 30px;
          object-fit: cover;
        }
      }
      @include media-breakpoint-down(lg) {
        margin-bottom: 20px !important;
      }
    }
    .card-body {
      @include media-breakpoint-up(xl) {
        padding: 25px 30px !important;
      }
    }
    .sc-heading {
      @extend .mb-20;
    }
    p {
      @extend .mb-10;
      &:last-child {
        @extend .mb-0;
      }
    }
    .link {
      @extend .font-13;
      @extend .mb-0;
      line-height: 18.2px;
      opacity: 0.5;
      text-decoration: none !important;
    }
  }
}
.resources-card-map {
  max-height: 640px;
  overflow-y: auto;
}

.programs-card {
  .card {
    height: calc(100% - 30px) !important;
  }
}

.card-media {
  max-width: 426px;
  border-radius: $border-radius-lg;
  .card-body {
    padding: 30px 40px 30px 30px !important;
  }
  @include media-breakpoint-up(xl) {
    margin-left: auto;
  }
  @include media-breakpoint-down(sm) {
    border-radius: 20px;
  }
}
.choose-file {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .overflow-hidden;
  label {
    @extend .flex-shrink-0;
    @extend .me-12;
    @extend .c-pointer;
    @extend .flexbox-align-c;
    @extend .rounded-circle;
    @extend .flex-column;
    width: 117px;
    height: 117px;
    background-color: #ebebeb;
    opacity: 0.7;
  }
  .cf-label {
    font-size: 12px;
    line-height: 16.8px;
    @extend .font-700;
    opacity: 0.7;
  }
  .cf-text {
    opacity: 0.4;
    font-size: 12px;
    line-height: 16.8px;
    @extend .font-700;
  }
  img {
    @extend .w-100;
    @extend .h-100;
    object-fit: cover;
  }
  @include media-breakpoint-down(md) {
    label {
      width: 101px;
      height: 101px;
    }
  }
}

.choose-file-card {
  border-radius: 10px;
  border: 2px dashed rgba($color: $dark, $alpha: 0.4);
  label {
    @extend .c-pointer;
    @extend .flexbox-align-c;
    @extend .flex-column;
    min-height: 306px;
    padding: 30px;
    transition: none;
  }
  &.img-selected {
    label {
      min-height: 124px !important;
    }
  }
}
