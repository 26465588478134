/*pixel to em convert*/

/*font-size: rem(16);*/
$browser-context: 16;
@function rem($pixels, $context: $browser-context) {
    @return #{calc($pixels/$context)}rem;
}

// black
/*box-shadow: 0 0 4px black(0.3);*/
@function black($opacity) {
    @return rgba(0, 0, 0, $opacity);
}

// white
/*box-shadow: 0 0 4px white(0.3);*/
@function white($opacity) {
    @return rgba(255, 255, 255, $opacity);
}
