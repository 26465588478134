.loader {
  @extend .position-fixed;
  @extend .start-0;
  @extend .top-0;
  @extend .flexbox-align-c;
  @extend .w-100;
  @extend .h-100;
  z-index: 1040;
  background-color: rgba($color: $dark, $alpha: 0.4);
  backdrop-filter: blur(2px);
}
.loader-item {
  @extend .position-absolute;
  @extend .rounded-circle;
  opacity: 0;
  height: 32px;
  width: 32px;
}
.loader-one {
  background: $primary;
  animation: loading 1.3s 0.65s infinite;
}

.loader-two {
  background: $secondary;
  animation: loading 1.3s infinite;
}

@keyframes loading {
  0% {
    opacity: 0;
    transform: scale(0.15);
    box-shadow: 0 0 2px rgba(black, 0.1);
  }
  50% {
    opacity: 1;
    transform: scale(2);
    box-shadow: 0 0 10px rgba(black, 0.1);
  }
  100% {
    opacity: 0;
    transform: scale(0.15);
    box-shadow: 0 0 2px rgba(black, 0.1);
  }
}
