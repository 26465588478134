.footer {
  margin-top: 100px;
  border-top: 1px solid rgba(0, 34, 51, 0.2);
  padding-top: 30px;
  padding-bottom: 30px;
  @include media-breakpoint-down(lg) {
    margin-top: 70px;
  }
  @include media-breakpoint-down(md) {
    margin-top: 40px;
  }
}
.footer-row {
  @extend .d-flex;
  @extend .flex-row;
  @extend .align-items-center;
  @extend .justify-content-between;
  // @extend .flex-wrap;
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
}
.footer-logo {
  cursor: pointer;
}
.footer-menu {
  margin-top: 60px;
  ul {
    @extend .list-unstyled;
    @extend .d-flex;
    @extend .flex-row;
    li {
      &:not(:last-child) {
        margin-right: 60px;
      }
    }
  }
  .fm-heading {
    font-weight: $font-weight-bold;
    @extend .d-inline-flex;
    @extend .mb-30;
  }
  .fm-sub {
    @extend .flex-column;
    li {
      margin-right: 0 !important;
      @extend .mb-10;
    }
  }
  @include media-breakpoint-down(lg) {
    margin-top: 40px;
    ul {
      li {
        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    margin-top: 24px;
    ul {
      flex-direction: column !important;
      li {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
    .fm-heading {
      margin-bottom: 12px !important;
    }
  }
}

.footer-text {
  margin-top: 44px;
  @extend .d-md-flex;
  @extend .justify-content-end;

  span {
    margin: 0 3px;
    opacity: 0.5;
    @extend .flex-shrink-0;
  }
  .ft-menu {
    @extend .list-unstyled;
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .mb-0;
    gap: 12px;
  }
  a {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  @include media-breakpoint-down(md) {
    margin-top: 24px;
  }
}
