.social-list {
  @extend .list-unstyled;
  @extend .d-flex;
  @extend .flex-row;
  @extend .mb-0;
  li {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.choose-list {
  @extend .mb-60;
  &-center {
    max-width: 767px;
    @extend .mx-auto;
  }
  &:not(&-auto) {
    .radio-item {
      @extend .w-100;
    }
    .form-check-label {
      @extend .w-100;
    }
  }
  .radio-item {
    &:not(:last-child) {
      @extend .mb-10;
    }
    input {
      @extend .d-none;
    }
  }
  .form-check-label {
    @extend .font-400;
    @extend .c-pointer;
    @extend .border;
    @extend .rounded-pill;
    @extend .d-flex;
    @extend .align-items-center;
    padding: 15.25px 50px;
  }
  .form-check-input {
    &:checked + .form-check-label {
      background-color: $dark;
      @extend .text-white;
    }
  }
  .cl-icon {
    @extend .me-8;
    width: 22px;
    height: 22px;
  }
  &-inline {
    &:not(.mt-0) {
      @extend .mt-15;
    }
    &:not(.mb-0) {
      @extend .mb-30;
    }
    @extend .d-flex;
    @extend .flex-wrap;
    .radio-item {
      @extend .w-auto;
      &:not(:last-child) {
        @extend .me-20;
      }
    }
    .form-check-label {
      padding-left: 30px;
      padding-right: 30px;
    }
    .form-check-input {
      &:checked + .form-check-label {
        @extend .bg-primary;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .form-check-label {
      padding: 12px 30px;
    }
    &-inline {
      .radio-item {
        @extend .w-auto;
        &:not(:last-child) {
          margin-right: 12px !important;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .form-check-label {
      padding: 8px 16px;
      font-size: 14px;
      span {
        display: block !important;
        margin-left: 0 !important;
      }
    }
    .cl-icon {
      margin-right: 4px !important;
      width: 20px;
      height: 20px;
    }
  }
}

.bullet-list {
  @extend .list-unstyled;
  @extend .ms-0;
  @extend .ps-0;
  @extend .mb-0;
  li {
    padding-left: 20px;
    @extend .position-relative;
    &:not(:last-child) {
      @extend .mb-3;
    }
    &:before {
      content: "";
      width: 10px;
      height: 10px;
      background-color: $secondary;
      @extend .position-absolute;
      @extend .start-0;
      @extend .rounded-circle;
      top: 6px;
    }
  }
}
