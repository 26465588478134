// utility Classes

@for $i from 6 through 36 {
  .font-#{$i} {
    font-size: #{$i}px !important;
  }

  .lh-#{$i} {
    line-height: #{$i}px !important;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .ps-#{$i} {
    padding-left: #{$i}px !important;
  }
  .pe-#{$i} {
    padding-right: #{$i}px !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .ms-#{$i} {
    margin-left: #{$i}px !important;
  }

  .me-#{$i} {
    margin-right: #{$i}px !important;
  }

  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }
}

:focus {
  outline: none;
  box-shadow: none;
}

.mt-sm {
  margin-top: var(--section-gutter-y-sm);
}

a {
  color: inherit;
}

.mw-717 {
  max-width: 717px;
}
.mw-554 {
  max-width: 542px;
}

.mw-690 {
  max-width: 690px;
}
.mw-340 {
  max-width: 340px;
}
.mw-425 {
  max-width: 425px;
}

.mt-60 {
  margin-top: 60px;
  @include media-breakpoint-down(xl) {
    margin-top: 40px;
  }
}
.mt-40 {
  margin-top: 40px;
  @include media-breakpoint-down(xl) {
    margin-top: 30px;
  }
}

.mb-60 {
  margin-bottom: 60px;
  @include media-breakpoint-down(xl) {
    margin-bottom: 40px;
  }
}

.bg-light {
  background-color: $gray-100;
}

.mw-1170 {
  @include media-breakpoint-up(xxl) {
    max-width: 1170px;
  }
}

.col-lc-mb-0 {
  .col {
    &:last-child {
      @extend .mb-0;
    }
  }
}

.filter-label {
  @extend .font-700;
  @extend .me-3;
  min-width: 132px;
}

.link {
  @extend .font-700;
  @extend .text-decoration-underline;
  font-size: rem(12);
  line-height: 17px;
  letter-spacing: 0em;
}

.gap-20 {
  gap: 20px;
}
.gap-10 {
  gap: 10px;
}

.text-dark-50 {
  color: rgba($color: $dark, $alpha: 0.5);
}

.text-12 {
  font-size: 12px;
  line-height: 16.8px;
}

.img-50 {
  @extend .rounded-circle;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.rounded-10 {
  border-radius: 10px !important;
}

.rounded-lg {
  border-radius: $border-radius-lg;
  @include media-breakpoint-down(sm) {
    border-radius: 20px;
  }
}

.p-mb-0 {
  p {
    @extend .mb-0;
  }
}

@include media-breakpoint-down(md) {
  .mb-30 {
    margin-bottom: 16px !important;
  }
  .mb-60 {
    margin-bottom: 30px !important;
  }
}

@include media-breakpoint-down(sm) {
  .mt-30 {
    margin-top: 20px !important;
  }
}

.search-program {
  min-width: 300px !important;
  padding-top: 11px;
  padding-bottom: 11px;
}

.text-line-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
