// links
/* @include link(white, blue, green, red); */
@mixin link($link, $visit, $hover, $active) {
  color: $link;

  &:visited {
    color: $visit;
  }

  &:hover {
    color: $hover;
  }

  &:active {
    color: $active;
  }
}

/* @include btn-link(white, blue, green, red); */
@mixin btn-link($link) {
  color: $link;

  &:visited {
    color: $link;
  }

  &:hover {
    color: $link;
  }

  &:active {
    color: $link;
  }
}

/*@include button-bg(#2ecc71);*/
@mixin button($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 25%);
    outline: none;
  }

  &:hover {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
      outline: none;
    }
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
      outline: none;
    }
  }
}

// box shadow
/* @include box-shadow(1px, 1px, 1px, 1px, #fff, true); */
@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $spread $color;
    -moz-box-shadow: inset $top $left $blur $spread $color;
    box-shadow: inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}

// @include default_transition(0.3s, ease-in-out);
@mixin default_transition($time, $effect) {
  -moz-transition: all $time $effect;
  -webkit-transition: all $time $effect;
  -ms-transition: all $time $effect;
  -o-transition: all $time $effect;
  transition: all $time $effect;
}

// Usage - @include transition(color .3s ease);
/*@include transition(all 0.2s ease-in-out);*/
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

// Usage - @include transform-origin(100% 0);
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin; // IE9 only
  transform-origin: $origin;
}

/* @include prefix('border-radius', $radius); */
@mixin prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

// border radius
/* @include border-radius(25px); */
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

// Single side border-radius
//  @include border-top-radius(10px);
@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

//  @include border-right-radius(8px);
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  background-clip: padding-box;
}

//  @include border-bottom-radius(10px);
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}

//  @include border-left-radius (6px);
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

/*@include border-radius-separate(1px, 2px, 3px, 4px);*/
@mixin border-radius-separate(
  $topLeftRadius: 5px,
  $topRightRadius: 5px,
  $bottomLeftRadius: 5px,
  $bottomRightRadius: 5px
) {
  -webkit-border-top-left-radius: $topLeftRadius;
  -webkit-border-top-right-radius: $topRightRadius;
  -webkit-border-bottom-right-radius: $bottomRightRadius;
  -webkit-border-bottom-left-radius: $bottomLeftRadius;

  -moz-border-radius-topleft: $topLeftRadius;
  -moz-border-radius-topright: $topRightRadius;
  -moz-border-radius-bottomright: $bottomRightRadius;
  -moz-border-radius-bottomleft: $bottomLeftRadius;

  border-top-left-radius: $topLeftRadius;
  border-top-right-radius: $topRightRadius;
  border-bottom-right-radius: $bottomRightRadius;
  border-bottom-left-radius: $bottomLeftRadius;
}

// gradient
// @include gradient-directional(35deg, #b37399, #ff5435);
@mixin gradient-directional($deg, $start-color, $end-color) {
  background-repeat: repeat-x;
  background-image: -webkit-linear-gradient($deg, $start-color, $end-color);
  background-image: -o-linear-gradient($deg, $start-color, $end-color);
  background-image: linear-gradient($deg, $start-color, $end-color);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
}

/// Background Gradient
/*@include background-gradient(#b37399, #ff5435);*/
@mixin background-gradient($startColor: #3c3c3c, $endColor: #999999) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(top, $startColor, $endColor);
  background-image: -moz-linear-gradient(top, $startColor, $endColor);
  background-image: -ms-linear-gradient(top, $startColor, $endColor);
  background-image: -o-linear-gradient(top, $startColor, $endColor);
  background-image: linear-gradient(top, $startColor, $endColor);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}

/// Background Horizontal
/*@include background-horizontal(#b37399, #ff5435);*/
@mixin background-horizontal($startColor: #3c3c3c, $endColor: #999999) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(left, $startColor, $endColor);
  background-image: -moz-linear-gradient(left, $startColor, $endColor);
  background-image: -ms-linear-gradient(left, $startColor, $endColor);
  background-image: -o-linear-gradient(left, $startColor, $endColor);
  background-image: linear-gradient(left, $startColor, $endColor);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}', gradientType='1');
}

/// Background Radial
/*@include background-radial(#b37399, 50%, #ff5435, 100%);*/
@mixin background-radial($startColor: #ffffff, $startPos: 0%, $endColor: #000000, $endPos: 100%) {
  background: -moz-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop($startPos, $startColor),
    color-stop($endPos, $endColor)
  );
  background: -webkit-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
  background: -o-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
  background: -ms-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
  background: radial-gradient(ellipse at center, $startColor $startPos, $endColor $endPos);
}

/*@include gradient-with-image(90deg, #b37399, #ff5435, url);*/
@mixin gradient-image($deg, $start-color, $end-color, $url) {
  background: url($url) no-repeat, -webkit-linear-gradient($deg, $start-color, $end-color);
  background: url($url) no-repeat, -o-linear-gradient($deg, $start-color, $end-color);
  background: url($url) no-repeat, linear-gradient($deg, $start-color, $end-color);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
}

// Flexbox
// @include flexbox();
@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// placeholder
// @include input-placeholder {color: red; font-size: 16px;}
@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

// text overflow ellipsis Truncate
// @include text-ellipsis(100%);
@mixin text-ellipsis($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// @include text-ellipsis-line(2);
@mixin text-ellipsis-line($lines: 2) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  text-overflow: -o-ellipsis-lastline;
}

//::before and ::after
// @include pseudo;
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

// overlay
//  @include overlay();
@mixin overlay() {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

// Opacity
//  @include opacity(0.8);
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// Triangle
@mixin triangle($direction, $size: 6px, $color: #222) {
  content: "";
  display: block;
  position: absolute;
  height: 0;
  width: 0;

  @if ($direction == "up") {
    border-bottom: $size solid $color;
    border-left: 1/2 * $size solid transparent;
    border-right: 1/2 * $size solid transparent;
  } @else if ($direction == "down") {
    border-top: $size solid $color;
    border-left: 1/2 * $size solid transparent;
    border-right: 1/2 * $size solid transparent;
  } @else if ($direction == "left") {
    border-top: 1/2 * $size solid transparent;
    border-bottom: 1/2 * $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == "right") {
    border-top: 1/2 * $size solid transparent;
    border-bottom: 1/2 * $size solid transparent;
    border-left: $size solid $color;
  }
}

// clearfix
// @include clearfix();
@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

/*@include rotate(-45deg);*/
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

/*@include rotatex(-45deg);*/
@mixin rotatex($degrees) {
  -webkit-transform: rotatex($degrees);
  -moz-transform: rotatex($degrees);
  -ms-transform: rotatex($degrees);
  -o-transform: rotatex($degrees);
  transform: rotatex($degrees);
}

/*@include translate(-50%, -50%);*/
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}
