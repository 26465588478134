//  Ovarlay
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba($black, 0.2) !important;
  opacity: 0;
  visibility: hidden;
}

.has-overlay {
  .overlay {
    pointer-events: auto;
    opacity: 1;
    z-index: 1020;
    visibility: visible;
  }
}
